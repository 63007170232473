<template>
  <div>
    <div class="d-flex justify-content-center my-2" v-if="isLoading">
      <SpinnerLoader />
    </div>
    <div class="d-flex justify-content-center w-100 mt-3">
      <div
        id="g_id_onload"
        data-client_id="176664398759-8kl6tunfojvq2427sbod3g415qhevl1h.apps.googleusercontent.com"
        data-context="use"
        data-ux_mode="popup"
        data-callback="signUpWithGoogle"
        data-auto_prompt="false"
      ></div>

      <div
        class="g_id_signin"
        data-type="standard"
        data-shape="rectangular"
        data-theme="outline"
        data-text="continue_with"
        data-size="large"
        data-logo_alignment="center"
        data-width="100%"
      ></div>
    </div>
    <div class="or-line-container my-5">
      <div class="or-line"></div>
      <div class="or-text">OR</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AuthWithGoogle",
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    google.accounts.id.initialize({
      client_id:
        "176664398759-8kl6tunfojvq2427sbod3g415qhevl1h.apps.googleusercontent.com",
      callback: this.onClickHandler,
      cancel_on_tap_outside: false,
    });

    google.accounts.id.renderButton(document.getElementById("g_id_onload"), {
      theme: "outline",
      size: "large",
      text: "Sign in with Google",
    });
  },
  methods: {
    onClickHandler(response) {
      const idToken = response.credential;
      this.signUpWithGoogle(idToken);
    },
    async signUpWithGoogle(idToken) {
      this.isLoading = true;
      try {
        const { data } = await axios.post("auth/google", {
          idToken,
        });
        await this.$store.dispatch("setToken", data.token);
        await this.$store.dispatch("setRefToken", data.refreshToken);
        await this.$store.dispatch("setUser", data.user);
        this.$toast.success(`Logged in. Redirecting to console`);
        this.$router.push("/dash/Overview");
      } catch (error) {
        console.error("An error occurred:", error.message);
        this.$toast.error("An error occurred. Please try again");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.or-line-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.or-line {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.or-text {
  position: absolute;
  top: -10px;
  padding: 0 10px;
  background-color: #fff;
  font-weight: bold;
  color: #777;
  border-radius: 8px;
}
</style>
