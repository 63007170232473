<template>
  <div class="auth-wrapper">
    <div class="col-md-4 mx-auto form-holder">
      <div class="my-5 d-flex align-items-center justify-content-center">
        <img
          :src="
            AppTheme === 'dark_theme'
              ? require('@/assets/img/brand/ana_white.png')
              : require('@/assets/img/brand/ana.png')
          "
          class="logo"
          alt=""
        />
      </div>

      <form @submit.prevent="login" class="app-form">
        <div class="mt-5">
          <h3 class="text-center ana_h3 ana_text-grey">Welcome back!</h3>
          <p class="text-center ana_paragraph ana_text-grey">
            Login to get started
          </p>
        </div>
        <AuthWithGoogle />
        <div class="row">
          <div class="col-lg-12">
            <p class="text-danger small">{{ authMessage }}</p>
          </div>
          <div class="form-group col-lg-12">
            <label for="password" class="ana-label">Email Address</label>
            <input
              type="email"
              name="email"
              class="ana-input w-100"
              v-model.trim="$v.email.$model"
              placeholder="Email"
              required
            />
            <InlineAlert
              alertMessage="Be sure to provide a valid email"
              alertType="error"
              v-if="email && $v.email.$invalid"
            />
          </div>
          <div class="form-group col-lg-12">
            <label for="password" class="ana-label">Password</label>
            <div class="input-group mb-3 ana-input__group">
              <input
                :type="type"
                name="password"
                class="form-control ana-input"
                v-model.trim="$v.password.$model"
                placeholder="min. 6 characters"
                required
              />
              <div class="input-group-append">
                <span
                  class="input-group-text cursor-pointer"
                  id="basic-addon2"
                  @click="showPassword"
                >
                  <i
                    class="fa fa-eye"
                    :class="{ 'fa fa-eye-slash': type == 'text' }"
                  ></i>
                </span>
              </div>
            </div>
            <InlineAlert
              alertMessage="Password should be at least 6 characters"
              alertType="error"
              v-if="password && $v.password.$invalid"
            />
            <div class="text-right">
              <router-link class="ana-link" to="/ForgotPassword/"
                >Forgot Password</router-link
              >
            </div>
          </div>
        </div>
        <div class="mt-3 mb-3 d-flex justify-content-center" v-if="isLoading">
          <div class="linear-loader"></div>
        </div>
        <div class="form-group">
          <button
            type="submit"
            class="ana-btn ana-btn__fill w-100"
            :disabled="isLoading || $v.$invalid"
          >
            Login
          </button>
        </div>
        <div class="mt-5 text-center">
          <p class="ana-text--medium ana_text-grey">
            Don't have an account?
            <router-link to="/SignUp" class="ana_text-secondary">
              Sign Up</router-link
            >
          </p>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
import AuthWithGoogle from "@/components/AuthWithGoogle";
import { required, minLength, between, email } from "vuelidate/lib/validators";
export default {
  name: "Login",
  metaInfo: {
    title: "Login - Ana app",
  },
  components: {
    AuthWithGoogle,
  },
  data() {
    return {
      email: "",
      password: "",
      type: "password",
      authMessage: "",
      isLoading: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
    },
  },
  computed: {
    ...mapState(["isUserLoggedIn", "user"]),
  },
  // mounted() {
  //   if (this.isUserLoggedIn) {
  //     this.$router.push('/dash/Overview')
  //   }
  // },
  methods: {
    login() {
      this.isLoading = true;
      axios
        .post("login", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          this.$store.dispatch("setToken", res.data.token);
          this.$store.dispatch("setRefToken", res.data.refreshToken);
          this.$store.dispatch("setUser", res.data.user);
          this.isLoading = false;
          this.$toast.success(`Logged in. Redirecting to console`);
          this.$router.push("/dash/Overview");
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast.error(`${error.response.data.message}`);
        });
    },
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
  },
};
</script>
